import React from 'react';
import lombard from '../img/lom-logo.png';
import puls from '../img/puls-logo.png';
import poczta from '../img/poczta-logo.png';
import police from '../img/policja-logo.png';
import fundacja from '../img/fundacja-logo.png';
import zbp from '../img/zbp-logo.png';
import kgw from '../img/kgw-logo.png';
import pkp from '../img/pkp-logo.png';
import eri from '../img/pz-eri-logo.png';
import gs from '../img/gs-logo.png';
import radiopogoda from '../img/radio-pogoda-logo.png';
import utw from '../img/utw-logo.png';
import eskumed from '../img/eskumed-logo.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

function SwiperSliderAkcjeWspiera() {
  return (
    <>
      <div className='hidden md:flex justify-center my-8'>
        <p className='text-center text-[#ED6D28] '>Akcję wspierają</p>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={60}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <a
            href='https://kolagospodynwiejskich.org/'
            target='_blank'
            rel='noopener noreferrer'
            className='hover:cursor-pointer'
          >
            <img src={kgw} alt='logo kgw' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://www.poczta-polska.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={poczta} alt='logo poczta' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://www.pkp.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={pkp} alt='logo poczta' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='http://emeryci.milanowek.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={eri} alt='logo poczta' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://glosseniora.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={gs} alt='glos seniora' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://www.facebook.com/people/Pogotowie-Medyczne-Eskumed/100034708226658/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={eskumed} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://radiopogoda.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={radiopogoda} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://federacjautw.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={utw} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SwiperSliderAkcjeWspiera;
