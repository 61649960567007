import React from 'react';
import { FaQuoteRight } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

function CommentsSlider() {
  return (
    <div className='mt-12'>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        autoHeight={true}
        pagination={{
          clickable: true,
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        className='CommentsSlider'
      >
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Chcemy wykorzystać ogromną popularność naszego serialu „Lombard.
              Życie pod zastaw” i wraz z ekipą serialu, Komendą Główną Policji
              oraz Fundacją TV Puls „Pod Dębem” inicjujemy ogólnopolską kampanię
              edukacyjną. Wspólnie opracowaliśmy listę działań, które - mamy
              nadzieję - pomogą zminimalizować ryzyko nabrania się na
              psychomanipulacje oszustów. To m.in. ustalenie hasła-klucza w
              komunikacji z najbliższymi, weryfikacja tożsamości osoby
              dzwoniącej czy stworzenie osobistej „Listy wsparcia”. Stąd też
              wieloznaczne hasło naszej kampanii #ZnamTeNumery – znam „numery”,
              czyli sposoby przestępców, ale również znam numery telefonu do
              osób, na które mogę liczyć w sytuacji zagrożenia, w tym m.in. do
              dzielnicowego{' '}
              <span className='font-bold'>
                – powiedziała Justyna Ganczarek, Dyrektor Marketingu i Sprzedaży
                Telewizji Puls.
              </span>{' '}
              – Nasza poprzednia wspólna kampania społeczna organizowana z
              Komendą Główną Policji pod hasłem #DzielnicaBEZstrachu cieszyła
              się ogromnym powodzeniem; dzięki niej przypomnieliśmy, że każdy z
              nas może zwrócić się o pomoc do swojego dzielnicowego. Mamy
              nadzieję, że kampania #ZnamTeNumery zwiększy świadomość seniorów i
              ich bliskich na temat zagrożeń, jakie czyhają w sieci czy wiążą
              się z lekkomyślnym podawaniem danych osobowych.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Bezpieczeństwo seniorów jest jednym z obszarów traktowanych przez
              Policję w sposób priorytetowy. Zdarzeniami naruszającymi to
              bezpieczeństwo są m.in. oszustwa na szkodę seniorów na tzw.
              legendę, tj. np. na wnuczka. Należy pamiętać, że oprócz strat
              materialnych, które ponoszą osoby pokrzywdzone przestępstwem,
              występują jeszcze społeczne jego skutki. Dlatego w celu
              zapobiegania tego typu zdarzeniom policjanci realizują wiele
              różnorodnych działań informacyjno-edukacyjnych. Aby podnieść
              skuteczność i dotrzeć do jak najszerszej grupy odbiorców,
              oddziaływania te podejmujemy z różnymi Partnerami. I tak wspólnie
              z Telewizją Puls oraz Fundacją „Pod Dębem” rozpoczynamy Kampanię
              #ZnamTeNumery –{' '}
              <span className='font-bold'>
                powiedział insp. Piotr Kulesza, Zastępca Dyrektora Biura
                Prewencji Komendy Głównej Policji.
              </span>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Z ogromną radością dołączamy do ogólnopolskiej inicjatywy
              #ZnamTeNumery. Jest niezmiernie ważne, aby nie tylko w dniach
              szczególnych dla osób starszych, tj. 1 października i 14
              listopada, podkreślać potrzebę ochrony ich praw oraz zapewnienia
              im bezpieczeństwa. Razem możemy stworzyć społeczeństwo, które
              troszczy się o swoich starszych członków i chroni ich przed
              wszelkimi formami wykorzystywania –{' '}
              <span className='font-bold'>
                powiedziała Erin Dąbska, Prezes Fundacji TV Puls „Pod Dębem”.
              </span>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Kochani nasi Seniorzy, Kochani nasi Telewidzowie. Wiemy, że od lat
              nas oglądacie. Dzięki temu możemy gościć w Waszych domach, czujemy
              się jak Wasza telewizyjna rodzina i czujemy się z Wami związani -
              a tym samym za Was odpowiedzialni. Z całego serca pragniemy
              Waszego spokoju i szczęścia. Nie chcemy fałszywych wnuczków,
              pseudopolicjantów i naciągaczy. Czujemy się zaszczyceni, że dzięki
              naszej akcji możemy przestrzec Was przed oszustwami. Nie dajmy
              się! –{' '}
              <span className='font-bold'>
                powiedziała Małgorzata Szeptycka, aktorka serialu „Lombard.
                Życie pod zastaw”.
              </span>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Zaangażowaliśmy się w promocję kampanii #ZnamTeNumery, wiedząc,
              jak bardzo potrzebne jest to działanie. Polskie Koleje Państwowe
              S.A. od lat dbają o bezpieczeństwo podróżnych na dworcach
              kolejowych, a wsparcie kampanii #ZnamTeNumery wpisuje się w naszą
              strategię działań CSR-owych. Materiały promujące akcję są
              wyświetlane na dworcach w Warszawie, Wrocławiu, Poznaniu i
              Katowicach. W ramach kampanii powstaną także m.in. materiały
              poświęcone bezpiecznemu poruszaniu się pasażerów po dworcach
              kolejowych oraz umożliwiające zlokalizowanie znajdujących się na
              nich ważnych punktów –
              <span className='font-bold'>
                powiedział Michał Stilger, rzecznik prasowy PKP S.A.
              </span>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Cieszymy się, że Polski Związek Kół Gospodyń Wiejskich może
              przyczynić się do rozpropagowania akcji #ZnamTeNumery wśród
              lokalnych społeczności. Obecnie Polski Związek KGW zrzesza ponad
              3000 kół gospodyń wiejskich z całej Polski i wciąż pojawiają się
              nowi członkowie, więc docieramy, często w ramach bezpośrednich
              spotkań, z przekazem do bardzo licznej grupy –
              <span className='font-bold'>
                powiedziała Katarzyna Korus, Prezes Polskiego Związku Kół
                Gospodyń Wiejskich.
              </span>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=' bg-white rounded-xl shadow-lg py-8 px-8'>
            <FaQuoteRight size={48} fill='#ED6D28' />
            <p className='mt-4'>
              Uważajmy na niepokojące SMS-y i telefony, będące próbą wyłudzenia
              danych osobowych czy pieniędzy. Chwila nieuwagi i brak czujności
              mogą nas bowiem bardzo drogo kosztować. Jesteśmy dumni, że Polski
              Związek Emerytów, Rencistów i Inwalidów jest partnerem tak bardzo
              ważnej akcji –
              <span className='font-bold'>
                powiedziała Elżbieta Ostrowska, Przewodnicząca Polskiego Związku
                Emerytów, Rencistów i Inwalidów.
              </span>
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default CommentsSlider;
