import React, { useEffect, useState } from 'react';
import logo from '../img/page-logo.png';
import lombard from '../img/lom-logo.png';
import puls from '../img/puls-logo.png';
import police from '../img/policja-logo.png';
import fundacja from '../img/fundacja-logo.png';
import zbp from '../img/zbp-logo.png';
import fincert from '../img/fincert-logo.png';
import kgw from '../img/kgw-logo.png';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import ScrollIntoView from 'react-scroll-into-view';
import SwiperSliderLogo from './SwiperSliderLogo';

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const styles = {
    active: {
      color: isActive ? '#ED6D28' : 'initial',
    },
  };

  useEffect(() => {
    const html = document.querySelector('html');

    html.style.overflow = menuActive ? 'hidden' : 'auto';
  }, [menuActive]);
  return (
    <div className='container mx-auto mt-5 px-4 flex flex-wrap justify-between'>
      <img src={logo} alt='logo' className='w-32 md:w-48' />

      <ul className='hidden md:flex justify-center items-center flex-1 text-sm'>
        <li className='mx-2' style={styles.active}>
          O akcji
        </li>
        <ScrollIntoView selector='#section-2'>
          <li className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer'>
            Jak nie dać się nabrać
          </li>
        </ScrollIntoView>
        <ScrollIntoView selector='#section-2'>
          <li className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer'>
            Obejrzyj video
          </li>
        </ScrollIntoView>
        <ScrollIntoView selector='#movie'>
          <li className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer'>
            O serialu
          </li>
        </ScrollIntoView>
        <ScrollIntoView selector='#kgw'>
          <li className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer p-2 rounded-md'>
            Konkurs
          </li>
        </ScrollIntoView>
      </ul>
      {menuActive && (
        <div className='fixed top-0 w-full h-screen bg-white pt-10 px-8 z-40 flex items-center justify-center'>
          <ul className='text-3xl leading-loose'>
            <li className='mx-2' onClick={() => setMenuActive((prev) => !prev)}>
              O akcji
            </li>
            <ScrollIntoView selector='#section-2'>
              <li
                className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer'
                onClick={() => setMenuActive((prev) => !prev)}
              >
                Jak nie dać się nabrać
              </li>
            </ScrollIntoView>
            <ScrollIntoView selector='#section-2'>
              <li
                className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer'
                onClick={() => setMenuActive((prev) => !prev)}
              >
                Obejrzyj video
              </li>
            </ScrollIntoView>
            <ScrollIntoView
              selector='#movie'
              onClick={() => setMenuActive((prev) => !prev)}
            >
              <li className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer'>
                O serialu
              </li>
            </ScrollIntoView>
            <ScrollIntoView
              selector='#kgw'
              onClick={() => setMenuActive((prev) => !prev)}
            >
              <li className='mx-2 hover:text-[#ED6D28] hover:cursor-pointer kgw'>
                Konkurs
              </li>
            </ScrollIntoView>
          </ul>
        </div>
      )}
      <div
        className='md:hidden z-50'
        onClick={() => setMenuActive((prev) => !prev)}
      >
        {menuActive ? <IoMdClose size={48} /> : <IoMdMenu size={48} />}
      </div>
      <SwiperSliderLogo />
    </div>
  );
}

export default Header;

{
  /* <div className='-order-1 mb-4 md:mb-0 mx-auto md:order-1 flex justify-end items-center grow-0'> */
}
{
  /* <div className='flex justify-center items-center'>
  <a
    href='https://tvpuls.pl/'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={puls}
      alt='logo puls'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
  <a
    href='https://tvpuls.pl/seriale/lombard-zycie-pod-zastaw'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={lombard}
      alt='logo lombard'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
  <a
    href='https://policja.pl/'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={police}
      alt='logo policja'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
  <a
    href='https://fundacjapoddebem.pl/'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={fundacja}
      alt='logo fundacja'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
  <a
    href='https://www.zbp.pl/'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={zbp}
      alt='logo zbp'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
  <a
    href='https://zbp.pl/Dla-Bankow/Cyberbezpieczenstwo/Cyberbezpieczenstwo-bankow-i-ich-klientow'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={fincert}
      alt='logo fincert'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
  <a
    href='https://kolagospodynwiejskich.org/'
    target='_blank'
    rel='noopener noreferrer'
    className='hover:cursor-pointer'
  >
    <img
      src={kgw}
      alt='logo kgw'
      className='block mx-auto w-16 md:w-24'
    />
  </a>
</div> */
}
{
  /* </div> */
}
